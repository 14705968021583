import React from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import logo from '../../assets/asklog-dev-logo.png'

import { CardBox } from '../CardBox';

export function CardProduct() {
  const navigate = useNavigate();

  return (
    <CardBox>
      <Box>
        <Box
          // align="center"
          px={6}
          py={3}
        >
          <img
            src={logo}
            alt="Askklog Dev"
            // layout="responsive"
          />
        </Box>
        <Divider />
        <Box px={4} py={2}>
          <Typography
            variant="body2"
            component="p"
            align="center"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </Box>
        <Divider />
        <Box px={4} pt={2} pb={3}>
          <Stack mb={2}>
            <Typography
              variant="body2"
              component="p"
              align="center"
            >
              a partir de
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              align="center"
            >
              R$135,00
            </Typography>
          </Stack>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/checkout")}
            fullWidth
          >
            Selecionar produto
          </Button>
        </Box>
      </Box>
    </CardBox>
  )
}
