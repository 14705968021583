import styled from "@emotion/styled";

export const AnchorDiv = styled.div`
  position: relative;
  top: -120px;
`

export const InputCreditCard = styled.div`
  border: 1px solid #CCC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  input {
    border: none;
    font-size: 1rem;
    height: 2rem;
    margin: 0 4px;
    outline: none;
    padding: 6px;
    width: 240px;
  }
  .CodeCreditCard {
    margin-left: auto;
  }
  div input {
    width: 72px;
  }
  .ImageCreditCard {
    background-color: #EEE;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-right: 10px;
    width: 40px;

    img {
      height: auto;
      max-width: 100%;
    }

  }
`

export const ResumeCheckout = styled.div`
  background-color: #F5F5F5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 1rem;

  p span {
    margin-left: .5rem;
  }

`
