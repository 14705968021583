import React from 'react';

import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {numberToReal} from "../../utils/money";

interface SelectQtyProps {
  onChange(qty: number): void;
  qty: number;
}

export function SelectQty({ onChange, qty }: SelectQtyProps) {
  const productAmount = 20;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(Number(event.target.value));
  };

  return (
    <Box mb={6}>
      <TableContainer>
        <Table aria-label="Comprar testes">
          <TableHead
            sx={{
              backgroundColor: '#f7f7f7',
            }}
          >
            <TableRow>
              <TableCell>
                <Typography>Produto</Typography>
              </TableCell>
              <TableCell>
                <Typography>Quantidade de testes</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <strong>
                    Total
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography>ASKKLOGdev</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <FormControl fullWidth size="small">
                    {/* <InputLabel id="demo-simple-select-label">Quantidade</InputLabel> */}
                    <Select
                      value={String(qty)}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={10}>10 testes</MenuItem>
                      <MenuItem value={20}>20 testes</MenuItem>
                      <MenuItem value={30}>30 testes</MenuItem>
                    </Select>
                  </FormControl>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <strong>
                    {numberToReal(productAmount * qty)}
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
