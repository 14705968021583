import * as React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {useNavigate} from "react-router-dom";

import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Container from '@mui/material/Container';

import { Brand } from '../../components';
import {useAppData} from "../../hooks";

const drawerWidth = 245;

interface DashboardProps {
  children: Element | React.ReactNode | React.ReactNode[];
  window?: (...attrs: any) => any;
}

export default function Dashboard(props: DashboardProps) {
  const { children, window } = props;

  const { user }  = useAppData();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box>
      <Toolbar>
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            width: {
              xs: "100px",
              md: "125px",
            }
          }}
          onClick={() => navigate("/")}
        >
          <Brand />
        </Box>
      </Toolbar>
      <Divider />
      <List sx={{
        mt: 2,
      }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Comprar testes" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/historic")}>
            <ListItemIcon>
              <ShoppingBagIcon />
            </ListItemIcon>
            <ListItemText primary="Minhas compras" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  // @ts-ignore
  return (
    <Box sx={{
      display: 'flex',
    }}>
      <CssBaseline />
      <AppBar
        variant="outlined"
        position="fixed"
        sx={{
          backgroundColor: "white",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 1,
                display: { md: 'none' } 
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Box
              sx={{
                cursor: "pointer",
                display: {
                  xs: "flex",
                  md: "none",
                },
                width: "110px",
              }}
              onClick={() => navigate("/")}
            >
              <Brand />
            </Box>
          </Box>

          <Box>
            <ListItem disablePadding>
              <ListItemButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <ListItemText
                  primary={user?.name}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                    color: "#343A40",
                    mr: 1,
                  }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 35,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box
                sx={{ width: 275, maxWidth: '100%' }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircle sx={{ fontSize: 30 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user?.name} secondary={user?.email} />
                </ListItem>
                <Divider />
                <ListItemButton onClick={() => navigate("/profile")}>
                  <ListItemText primary="Meus dados" />
                </ListItemButton>
                <Divider />
                <ListItemButton>
                  <ListItemText primary="Sair" onClick={() => logout()} />
                </ListItemButton>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: {
            xs: 4,
            lg: 5,
          },
          px: {
            lg: 6,
          },
          paddingBottom: 10,
        }}
      >
        <>
          <Toolbar />
          {children}
        </>
      </Container>

    </Box>
  );
}
