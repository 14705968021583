import React from 'react';

import get from 'lodash/get';
import moment from 'moment';

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";

interface HistoricItemProps {
  item: any;
}

export function HistoricItem({ item }: HistoricItemProps) {
  const qtyTotal = get(item, 'order.0.quantity');
  const purchaseDate = moment(new Date(get(item, 'startDate'))).format('DD/MM/YYYY')

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: "15px",
        marginBottom: 2,
      }}
    >
      <Box
        px={5} py={4}
      >
        <Grid
          container
          spacing={{
            xs: 3,
          }}
        >
          <Grid
            item
            xs={6}
            sm={2}
            lg={1.5}
          >
            <Typography
              color="#868E96"
              variant="body1"
              component="h2"
              mb={1}
            >
              <strong>Status</strong>
            </Typography>
            {/* Status da compra: Ativo */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <CircleIcon
                sx={{
                  color: "#29E88C",
                  fontSize: "10px"
                }}
              />
              <Typography>
                Ativo
              </Typography>
            </Stack>
            {/* Status da compra: Ativo */}

            {/* Status da compra: Finalizado */}
            {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <CircleIcon
                      sx={{
                        color: "#2797FF",
                        fontSize: "10px"
                      }}
                    />
                    <Typography>
                      Finalizado
                    </Typography>
                  </Stack> */}
            {/* Status da compra: Finalizado */}

            {/* Status da compra: Aguardando aprovação */}
            {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <CircleIcon
                      sx={{
                        color: "#E8C929",
                        fontSize: "10px"
                      }}
                    />
                    <Typography>
                      Aguardando aprovação
                    </Typography>
                  </Stack> */}
            {/* Status da compra: Aguardando aprovação */}

            {/* Status da compra: Pagamento negado */}
            {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <CircleIcon
                      sx={{
                        color: "#E82929",
                        fontSize: "10px"
                      }}
                    />
                    <Typography>
                      Negado
                    </Typography>
                  </Stack> */}
            {/* Status da compra: Pagamento negado */}

          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
          >
            <Typography
              color="#868E96"
              variant="body1"
              component="h2"
              mb={1}
            >
              <strong>Produto</strong>
            </Typography>
            <Typography>
              ASKKLOGdev
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
          >
            <Typography
              color="#868E96"
              variant="body1"
              component="h2"
              mb={1}
            >
              <strong>Teste totais</strong>
            </Typography>
            <Typography>
              {qtyTotal} testes
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            lg={2}
          >
            <Typography
              color="#868E96"
              variant="body1"
              component="h2"
              mb={1}
            >
              <strong>Teste restantes</strong>
            </Typography>
            <Typography>
              -
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
          >
            <Typography
              color="#868E96"
              variant="body1"
              component="h2"
              mb={1}
            >
              <strong>Data da compra</strong>
            </Typography>
            <Typography>
              {purchaseDate}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={2.5}
          >
            <Typography>
              <Button
                variant="outlined"
                fullWidth
              >
                Acessar teste
              </Button>
            </Typography>
          </Grid>
        </Grid>

      </Box>
    </Paper>
  );
}
