import React from "react";

import logo from '../../assets/asklog-logo.svg';

export function Brand() {
  return (
    <img
      src={logo}
      alt="Picture of the author"
    />
  )
}
