import styled from '@emotion/styled';
import Box from '@mui/material/Box';

import { theme } from '../styles/theme';

export const CardBox = styled(Box)`
  background-color: white;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 1rem;
  width: 100%;
`;
