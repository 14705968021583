import React from 'react';

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import {InputCreditCard} from "./styled";

interface CreditCardFormProps {
  onChange(fieldName: string, value: any): void;
  data: any;
  isLoading?: boolean;
}

function cc_format(value: string) {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  const matches = v.match(/\d{4,16}/g);
  const match = matches && matches[0] || ''
  const parts = []

  for (let i=0, len=match.length; i<len; i+=4) {
    parts.push(match.substring(i, i+4))
  }

  if (parts.length) {
    return parts.join(' ')
  } else {
    return value
  }
}

export function CreditCardForm({ onChange, data, isLoading }: CreditCardFormProps) {
  const onChangeNumber = (e: any) => onChange(
    'number',
    e.target.value ? cc_format(e.target.value.match(/\d+/g)?.join('')) : '',
  );
  const onChangeHolderName = (e: any) => onChange('holderName', e.target.value);
  const onChangeExpiresAt = (e: any) => onChange(
    'expiresAt',
    e.target.value ? e.target.value
      .match(/\d+/g)
      .join('')
      .replace(/(\d{1,2})(\d{2})(\d*)/, '$1/$2') : '',
  );
  const onChangeCvc = (e: any) => onChange(
    'cvc',
    e.target.value ? e.target.value
      .match(/\d+/g)
      .join('')
      .replace(/(\d{3,5})(\d*)/, '$1') : '',
  );

  return (
    <>
      <InputCreditCard>
        <div className="ImageCreditCard">
          {/* <img src={masterCardLogo} alt="Master Card"/> */}
        </div>
        <input
          type="text"
          placeholder="Número do cartão"
          disabled={isLoading}
          onChange={onChangeNumber}
          value={data.number}
        />
        <div className="CodeCreditCard">
          <input
            type="text"
            placeholder="MM/AA"
            onChange={onChangeExpiresAt}
            disabled={isLoading}
            value={data.expiresAt}
          />
          <input
            type="text"
            placeholder="CVC"
            onChange={onChangeCvc}
            disabled={isLoading}
            value={data.cvc}
          />
        </div>
      </InputCreditCard>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="ipt-card-name"
              label="Nome impresso no cartão"
              variant="outlined"
              onChange={onChangeHolderName}
              disabled={isLoading}
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
