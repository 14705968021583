import styled from '@emotion/styled';

export const FormFooterLinks = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  ${({ theme }) => `${theme.breakpoints.up('lg')} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  }`}

  p {
    margin-bottom: .5rem;
    ${({ theme }) => `${theme.breakpoints.up('lg')} {
      margin-bottom: 0;
    }`}
  }
`;
