import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  palette: {
    primary: {
      light: '#2797FF',
      main: "#2797FF",
      dark: '#0085FF',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'gilroy-semibold',
    subtitle1: {
      fontSize: 18,
    },
    body1: {
      fontFamily: 'gilroy-medium',
    },
  },
  breakpoints,
});




