import React from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import logo from "../../assets/asklog-dev-logo.png";

export function SideBar() {
  return (
    <Grid item xs={12} md={4}>
      <Box
        mb={2}
        sx={{
          maxWidth: 240,
        }}
      >
        <img
          src={logo}
          alt="Akklog Dev"
        />
      </Box>
      <Typography>O que é o askklog?</Typography>
      <Typography>Existem garantias de compra?</Typography>
      <Typography>Qual o contato de e-mail para possíveis suporte?</Typography>
      <Typography>Indicar pro usuário que é uma área segura para compras</Typography>
    </Grid>
  )
}
