import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

export default function Auth() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const authHandler = async () => {
      if (!isLoading && !isAuthenticated) {
        await loginWithRedirect();
      }
    }
    (async () => authHandler())();
  }, [isAuthenticated, isLoading]);
  
  return (
    <>
      <Container>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Container>
    </>
  ) 
}
