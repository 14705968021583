import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../../contexts/AppContext";
import { asklogPayment } from "../../data/integrations/AsklogPayment";

export function useCheckoutControl() {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const [status, setStatus] = useState('idle');
  const [statusMessage, setStatusMessage] = useState('');

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [numberTests, setNumberTests] = useState(10);
  const [identification, setIdentification] = useState({
    name: '',
    streetAddress: '',
    numberAddress: '',
    additionalAddress: '',
    zipAddress: '',
    cityAddress: '',
    stateAddress: '',
  });
  const [creditCardData, setCreditCardData] = useState({
    number: '',
    holderName: '',
    expiresAt: '',
    cvc: '',
  });

  const [paymentType, setPaymentType] = useState('credit_card');

  useEffect(() => {
    setStatus('idle');
  }, []);

  const onChangeIdentification = (field: string, value: string) => {
    setIdentification(data => ({ ...data, [field]: value }));
  }

  const onChangeCreditCardData= (field: string, value: string) => {
    setCreditCardData(data => ({ ...data, [field]: value }));
  }

  const clearFieldValue = (value: string, separator = ' ') => {
    return value.split(' ').filter(w => w.length).join(separator);
  }

  const purchase = useCallback(async () => {
    setStatus('loading');
    try {
      const { data: { status } } = await asklogPayment.purchase({
        "type": "credit_card",
        "productQuantity": numberTests,
        "customerName": user?.name,
        "customerEmail": user?.email,
        "customerType": "individual",
        "customerCode": user?.sub,
        "supplyData": {
          "number": clearFieldValue(creditCardData.number, ''),
          "holderName": clearFieldValue(creditCardData.holderName),
          "code": creditCardData.cvc,
          "expireAt": creditCardData.expiresAt
        }
      });

      console.log('status');

      if (status !== 'successful') {
        setStatus('error');
      } else {
        setStatus('success');
        navigate("/checkout/success")
      }
    } catch (e) {
      console.log('status error', e);
      setStatus('error');
      setStatusMessage((e as any).message);
    }
  }, [numberTests, creditCardData, user]);

  return useMemo(() => ({
    data: {
      qty: numberTests,
      creditCard: creditCardData,
      totalAmount: numberTests * 20,
      identification,
      paymentType,
      acceptTerms,
    },
    onChange: {
      identification: onChangeIdentification,
      creditCard: onChangeCreditCardData,
      qty: setNumberTests,
      paymentType: setPaymentType,
      setAcceptTerms,
    },
    actions: {
      purchase,
    },
    status: {
      error: status === 'error',
      success: status === 'success',
      loading: status === 'loading',
      statusMessage,
    }
  }), [numberTests, identification, paymentType, creditCardData, status, statusMessage, acceptTerms]);
}
