import React from 'react';

import Grid from '@mui/material/Grid';

import Dashboard from '../../layout/Dashboard';
import { CardProduct } from '../../components';

export default function Home() {
  return (
    <Dashboard>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <CardProduct />
        </Grid>
      </Grid>
    </Dashboard>
  )
}
