import React from 'react';

import Dashboard from "../../layout/Dashboard";

export default function Cart() {
  return (
    <Dashboard>
      <div>

      </div>
    </Dashboard>
  )
}
