import React from "react";

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

interface SuccessPageProps {
  children: React.ReactNode;
}

export function SuccessPage({ children }: SuccessPageProps) {
  return (
    <Container
      maxWidth="md"
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "calc(100vh - 4rem)",
        minHeight: "500px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: "center",
        }}
      >
        {children}
      </Stack>
    </Container>
  )
}
