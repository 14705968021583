import axios, { Axios } from "axios";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";

const BASE_URL = `https://${process.env.REACT_APP_AUTH0_DOMAIN}`;

const tokenOptions = {
  audience: `${BASE_URL}/api/v2/`,
  scope: "update:users update:users_app_metadata",
  ignoreCache: true,
};

type getTokenFn = Auth0ContextInterface["getAccessTokenSilently"];

export class Auth0 {
  getToken?: getTokenFn;

  static instance: Auth0;

  private httpClient: Axios | undefined;

  private static v2 = `${BASE_URL}/api/v2/`;

  constructor() {
    if (!Auth0.instance) {
      this.httpClient = axios.create({
        baseURL: Auth0.v2,
      });
      Auth0.instance = this;
    }
    return Auth0.instance;
  }

  static init(getToken: getTokenFn) {
    const auth0 = new Auth0();
    auth0.getToken = getToken;

    return auth0;
  }

  async getUserMetadata(userId: string) {
    const accessToken = await this.getToken?.(tokenOptions);
    const response = await this.httpClient?.get(`users/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    const { user_metadata: data } = response?.data as any;

    return data;
  }

  async updateUser(useId: string, newData: any) {
    const accessToken = await this.getToken?.(tokenOptions);
    return this.httpClient?.patch(
      `users/${useId}`, newData, { headers: {  'authorization': `Bearer ${accessToken}` },
    });
  }
}
