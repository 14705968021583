import React, {useCallback, useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Dashboard from '../../layout/Dashboard';
import { useAppData } from "../../hooks";

export default function Profile() {
  const { user, updateUser, addMessage }  = useAppData();

  const [profile, setProfile] = useState<any>({});
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  const onChangeField = useCallback((fieldName: string) => (e: any) => {
    setProfile((data: object) => ({ ...data, [fieldName]: e.target.value }));
  }, []);

  const save = useCallback(async () => {
    setUpdateLoading(true);
    await updateUser?.(String(user?.sub), {
      user_metadata: {
        name: String(profile.name),
        workers_qty: Number(profile.workersQty),
        company_name: profile.companyName || ''
      },
    });
    setUpdateLoading(false);
    addMessage?.('Dados salvo com sucesso.');
  }, [user, profile]);

  useEffect(() => {
    if (user) {
      console.log('user', user);
      setProfile(user);
    }
  }, [user]);

  return (
    <>
      <Dashboard>
        <Box
          mb={3}
        >
          <Typography
            variant="h4"
            component="h1"
          >
            <strong>Meus dados</strong>
          </Typography>
        </Box>
        <Box mb={4}>
          <Paper
            variant="outlined"
            sx={{
              borderRadius: "15px",
            }}
          >
            <Box
              px={5} py={4}
            >
              <Grid
                container
                spacing={{
                  xs: 2,
                  lg: 5,
                }}
                mb={3}
              >
                <Grid
                  item
                  xs={12}
                  lg={4}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    mb={1}
                  >
                    <strong>Meus dados</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={8}
                  mt={1}
                >
                  <Stack spacing={3}>
                    <FormControl fullWidth disabled>
                      <InputLabel htmlFor="ipt-email">E-mail</InputLabel>
                      <OutlinedInput
                        id="ipt-email"
                        label="E-mail"
                        type="email"
                        value={profile.email}
                        onChange={onChangeField('email')}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="ipt-name">Nome completo</InputLabel>
                      <OutlinedInput
                        id="ipt-name"
                        label="Nome completo"
                        type="text"
                        value={profile.name}
                        onChange={onChangeField('name')}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="ipt-company-name">Nome da empresa</InputLabel>
                      <OutlinedInput
                        id="ipt-company-name"
                        label="Nome da empresa"
                        type="text"
                        value={profile.companyName}
                        onChange={onChangeField('companyName')}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="ipt-qnt">Quantidade de funcionários</InputLabel>
                      <OutlinedInput
                        id="ipt-qnt"
                        label="Quantidade de funcionários"
                        type="number"
                        value={profile.workersQty}
                        onChange={onChangeField('workersQty')}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>

              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={save}
                >
                  {
                    updateLoading ? (
                      <CircularProgress
                        sx={{
                          color: "#FFF",
                          fontSize: "10px",
                        }}
                      />
                    ) : (
                      'Salvar'
                    )
                  }
                </Button>
              </Box>

            </Box>
          </Paper>
        </Box>
      </Dashboard>
    </>
  )
}
