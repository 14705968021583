import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import Dashboard from '../../layout/Dashboard';
import { SelectQty } from "./SelectQty";
import { AnchorDiv, ResumeCheckout } from './styled';
import {PaymentType} from "./PaymentType";
import {CreditCardForm} from "./CreditCardForm";
import {SideBar} from "./SideBar";
import {useCheckoutControl} from "./useCheckoutControl";
import {numberToReal} from "../../utils/money";


export default function Checkout() {
  const control = useCheckoutControl();

  return (
    <>
      <Dashboard>
        <Box
          mb={3}

        >
          <Typography
            variant="h4"
            component="h1"
          >
            <strong>Checkout</strong>
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} md={7}>
            <Box>
              <Paper
                variant="outlined"
                sx={{
                  borderRadius: "15px",
                }}
              >
                <Box
                  px={5} py={4}
                >
                  <AnchorDiv id="comprar-testes"/>
                  <SelectQty onChange={control.onChange.qty} qty={control.data.qty}/>
                  {/*<Identification data={control.data.identification} onChange={control.onChange.identification} />*/}
                  {/* Pagamento */}
                  <Typography
                    variant="h4"
                    mt={6}
                    mb={1}
                  >
                    Pagamento
                  </Typography>
                  <Typography
                    variant="body2"
                    mb={3}
                  >
                    Todos os campos são obrigatórios
                  </Typography>

                  <PaymentType selected={control.data.paymentType}/>
                  <CreditCardForm
                    onChange={control.onChange.creditCard}
                    data={control.data.creditCard}
                    isLoading={control.status.loading}
                  />
                  <Box mt={4} mb={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e: any) => control.onChange.setAcceptTerms(e.target.value)}
                            value={control.data.acceptTerms}
                          />
                        }
                        label="Estou de acordo com os termos"
                      />
                    </FormGroup>
                  </Box>
                  <Box mb={3}>
                    <ResumeCheckout>
                      <Typography
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        <strong>Pagamento Total: <span>{numberToReal(control.data.totalAmount)}</span></strong>
                      </Typography>
                      <Link href="#comprar-testes">Editar</Link>
                    </ResumeCheckout>
                  </Box>
                  <Box>
                    <LoadingButton
                      variant="contained"
                      size="large"
                      disabled={!control.data.acceptTerms}
                      onClick={control.actions.purchase}
                      loading={control.status.loading}
                    >
                      Finalizar compra
                    </LoadingButton>
                  </Box>
                  {
                    control.status.error && (
                      <Box mt={4}>
                        <Alert severity="error">{control.status.statusMessage}</Alert>
                      </Box>
                    )
                  }
                </Box>
              </Paper>
            </Box>
          </Grid>
          <SideBar/>
        </Grid>
      </Dashboard>
    </>
  );
}
