import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth0, User} from "@auth0/auth0-react";

import { Auth0 } from "../data/integrations/Auth0";
import {AppMessage} from "../components/Messages";

type AppUser = User & { workersQty: number, companyName: string };

interface AppContextValue {
  user?: AppUser | null;
  updateUser?: (userId: string, data: Partial<AppUser>) => Promise<any>;
  messages: AppMessage[];
  addMessage?: (text: string) => void;
}

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = React.createContext<AppContextValue>({ messages: [] });

export function AppContextProvider({ children }: AppContextProviderProps) {
  const [user, setUser] = useState<AppUser| null>(null);
  const [messages, setMessages] = useState<AppMessage[]>([]);
  const { user: userBase, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const auth0 = useRef(Auth0.init(getAccessTokenSilently));

  const loadUser = useCallback(async () => {
    if (!user) {
      const {
        name,
        email,
        workers_qty: workersQty,
        company_name: companyName,
      } = await auth0.current.getUserMetadata(String(userBase?.sub)) || {};
      setUser({
        ...userBase,
        ...{ workersQty, companyName },
        name: name || userBase?.name,
        email: email || userBase?.email,
      });
    }
  },[userBase, user]);

  const updateUser = useCallback(async (userId: string, newData: Partial<AppUser>) => {
    try {
      await auth0.current.updateUser(userId, newData);
      await loadUser();
    } catch (e) {
      setUser({ ...user as any });
    }
  },[user]);

  const removeMessageById = useCallback((id: string) => {
    setMessages(items => items.filter(item => item.id !== id));
  }, []);

  const addMessage = useCallback((text: string) => {
    const id = Math.random().toString(16).slice(2);
    setMessages(items => [...items, { id, text }]);
    setTimeout(() => removeMessageById(id), 4000);
  }, []);

  useEffect(() => {
    if (isAuthenticated && userBase) {
      (async () => await loadUser())();
    }
  }, [userBase, isAuthenticated]);

  return (
    <AppContext.Provider value={{ user, updateUser, messages, addMessage }}>
      {children}
    </AppContext.Provider>
  )
}
