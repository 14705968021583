import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import { ThemeProvider } from '@mui/material/styles';

import Home from "./screens/Home";
import Auth from "./screens/Auth";
import Historic from "./screens/Historic";
import Profile from "./screens/Profile";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";
import {Messages} from "./components";
import {CheckoutSuccess} from "./screens/CheckoutSuccess";
import { AppContextProvider } from "./contexts/AppContext";

import './styles/sass/styles.sass';
import './styles/fonts/gilroy/fonts.css';
import { theme } from './styles/theme';

export default function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <div className="App asklog-theme">
        <AppContextProvider>
          <BrowserRouter>
            <Routes>
              {
                isAuthenticated ? (
                  <>
                    <Route path="/" element={<Home />} />
                    {/*<Route path="/meus-dados" element={<Test />} />*/}
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/historic" element={<Historic />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/checkout/success" element={<CheckoutSuccess />} />
                  </>
                ) : (
                  <Route path="/" element={<Auth />} />
                )
              }
            </Routes>
            <Messages />
          </BrowserRouter>
        </AppContextProvider>
      </div>
    </ThemeProvider>
  );
}
