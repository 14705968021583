import axios, {Axios} from "axios";
import Config from "../../config";

export class AsklogPayment {
  private client: Axios;

  constructor() {
    this.client = axios.create({
      baseURL: Config.asklogPayment.domain,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  async purchase(data: any) {
    return await this.client.post('/transaction/purchase', data);
  }

  async listSuccessfulTransactions(code: string) {
    return await this.client.get('/transaction/purchase/list', { params: { customerCode: code } });
  }
}

export const asklogPayment = new AsklogPayment();
